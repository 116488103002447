<template>
  <div class="lp" v-if="entry">
    <!-- Legacy landing page -->
    <MatrixBlockLandingPageContent
      :matrix-blocks="entry.landingPageContent"
      :entry-type="entry.typeHandle"
      :related-posts-ids="relatedPostsIds"
      v-if="entry.landingPageContent"
    />
    <!-- 2023 landing page -->
    <MatrixBlockLandingPageContent
      :matrix-blocks="entry.landingPageContent2023"
      :entry-type="entry.typeHandle"
      :related-posts-ids="relatedPostsIds"
      v-if="entry.landingPageContent2023"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  useContext,
  useFetch,
  useMeta,
  useRoute,
  useStore,
} from '@nuxtjs/composition-api';
import { landingPageGql } from 'GQL/pages/landingPageGql.js';
import { log, selectedCategoriesFromCategoriesField } from 'JS/global.js';
import { gqlToObject } from 'JS/seomatic.js';
import MatrixBlockLandingPageContent from 'Components/matrix_blocks/MatrixBlockLandingPageContent.vue';

export default defineComponent({
  name: 'PageLandingPage',
  head: {},
  components: {
    MatrixBlockLandingPageContent,
  },
  setup() {
    const { app, $craft, payload, $preview } = useContext();
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      entry: null,
      relatedPostsIds: [],
      seomatic: null,
    });

    useFetch(async () => {
      let data;
      if (!$preview && payload?.entry) {
        data = payload;
      } else {
        const request = await $craft({
          apiLog: 'PageLandingPage',
          query: `query($site: String, $uri: String) { ${landingPageGql} }`,
          variables: {
            site: store.state.global.localeSites[app.i18n.locale].siteHandle,
            uri: `${route.value.params.contentSection}/page/${route.value.params.slug}`,
          },
        });

        if (request?.data) {
          data = request.data;
        }
      }

      if (data?.entry) {
        state.entry = data.entry;
        // console.log(state.entry);

        if (data.entry.relatedProductCategories) {
          state.relatedPostsIds = selectedCategoriesFromCategoriesField(data.entry.relatedProductCategories);
        }
        if (data.entry.seomatic) {
          state.seomatic = data.entry.seomatic;
        }
        if (state.entry?.navigationTheme) {
          store.commit('global/updateGlobalDarkTheme', true);
        } else {
          store.commit('global/updateGlobalDarkTheme', false);
        }
      }
    });

    useMeta(() => {
      return state.seomatic ? { ...gqlToObject(state.seomatic) } : {};
    });

    return { ...toRefs(state) };
  },
  mounted() {
    log('PageHome loaded');
  },
});
</script>

<style lang="scss">
.lp {
  position: relative;
}
</style>
